import React from "react";
import Footer from "src/components/Footer_gr";


class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/gr/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/gr/our-products/" target="_self">
                      ΤΑ ΠΡΟΪOΝΤΑ ΜΑΣ
                    </a>
                    <ul>
                      <li>
                        <a href="/gr/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/sensitive/">Sensitive</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>                      
                      <li>
                        <a href="/gr/our-products/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header"href="/gr/our-heritage/" target="_self">
                      Η ΜAΡΚΑ PIZ BUIN®
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/know_the_sun/" target="_self">
                      ΓΝΩΡΙΣΤΕ ΤΟΝ ΗΛΙΟ
                    </a>
                  </li>
               {/*<li>
                    <a className="es_header" href="/gr/Where-Have-You-Been/" target="_self">
                      Where Have You Been 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      ΧΩΡΕΣ
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/legal/legal-notice/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/legal/legal-notice/">UK</a>
                      </li>
                      <li>
                        <a href="/es/legal/aviso-legal/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/legal/aviso_legal/">PT</a>
                      </li>
                      <li>
                        <a href="/it/legal/legal-notice/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/legal/legal-notice/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="index-hero">
              <div id="bgImage">
                <div className="areaInner"></div>
              </div>
            </section>
            <section id="content">
            <div className="innerContent">
            <h2 className="element__intro">ΝΟΜΙΚΟΊ ΌΡΟΙ ΧΡΉΣΗΣ</h2>
               <p>Τελευταία ενημέρωση: 31 Μαρτίου 2016</p>
                <p>
                  Ο συγκεκριμένος δικτυακός τόπος (“Δικτυακός Τόπος”) σας
                  παρέχεται με βάση αυτούς τους "Όρους Χρήσης" και με τυχόν
                  τροποποιήσεις ή συμπληρώσεις αυτών (συνολικά αναφερόμενα ως
                  αυτή η "Συμφωνία") που μπορεί να αναρτηθούν κατά καιρούς από
                  Johnson &amp; Johnson Ελλάς Καταναλωτικά Προϊόντα Α.Ε.Ε. ή από
                  κάποια από τις συνδεδεμένες επιχειρήσεις αυτού (συλλογικά,
                  "Κάτοχος Δικτυακού Τόπου", "εμείς" "εμάς"). Η χρήση από εσάς
                  αυτού του Δικτυακού Τόπου, ή οποιωνδήποτε άλλων υπηρεσιών ή
                  περιεχομένου που παρέχεται μέσω του Δικτυακού Τόπου [ή Κινητού
                  Λογισμικού], θα θεωρείται ως συναίνεση από μέρους σας ότι
                  δεσμεύεστε νομικά από τους όρους και προϋποθέσεις της
                  Συμφωνίας, που ισχύουν ομοίως όπως αν είχατε υπογράψει τη
                  Συμφωνία. Εάν δεν επιθυμείτε να αποδεχθείτε τους όρους και
                  προϋποθέσεις της Συμφωνίας, σας ζητούμε να μην επιχειρήσετε
                  την πρόσβαση ή χρήση του Δικτυακού Τόπου ούτε την ανάρτηση ή
                  υποβολή οποιουδήποτε υλικού σε αυτόν.
                </p>
                <p>
                  <strong>Πού απευθύνεται ο Δικτυακός Τόπος</strong>
                </p>
                <p>
                  Αυτός ο Δικτυακός Τόπος προορίζεται και απευθύνεται στους
                  κατοίκους της Ελλάδας . Αυτός ο Δικτυακός Τόπος δεν
                  απευθύνεται σε άτομα ηλικίας κάτω των 18 ετών.
                </p>
                <p>
                  <strong>Αποποίηση ευθυνών σχετικά με τις πληροφορίες</strong>
                </p>
                <p>
                  ΟΙ ΠΛΗΡΟΦΟΡΙΕΣ, ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΩΝ ΤΥΧΟΝ ΣΥΜΒΟΥΛΩΝ ΚΑΙ
                  ΣΥΣΤΑΣΕΩΝ ΣΧΕΤΙΚΑ ΜΕ ΤΟΝ ΔΙΚΤΥΑΚΟ ΤΟΠΟ, ΠΡΟΟΡΙΖΟΝΤΑΙ
                  ΑΠΟΚΛΕΙΣΤΙΚΑ ΩΣ ΓΕΝΙΚΟ ΕΚΠΑΙΔΕΥΤΙΚΟ ΒΟΗΘΗΜΑ. ΔΕΝ ΠΡΟΟΡΙΖΟΝΤΑΙ
                  ΝΑ ΧΡΗΣΙΜΟΠΟΙΗΘΟΥΝ ΩΣ ΙΑΤΡΙΚΟ ΒΟΗΘΗΜΑ Ή ΟΔΗΓΟΣ ΥΓΕΙΑΣ, Ή ΓΙΑ
                  ΙΑΤΡΙΚΗ ΔΙΑΓΝΩΣΗ Ή ΘΕΡΑΠΕΙΑ, ΓΙΑ ΟΠΟΙΟΔΗΠΟΤΕ ΑΤΟΜΙΚΟ ΠΡΟΒΛΗΜΑ.
                  ΕΠΙΣΗΣ, ΔΕΝ ΠΡΟΟΡΙΖΟΝΤΑΙ ΝΑ ΥΠΟΚΑΤΑΣΤΗΣΟΥΝ ΤΙΣ ΕΠΑΓΓΕΛΜΑΤΙΚΕΣ
                  ΣΥΜΒΟΥΛΕΣ ΚΑΙ ΥΠΗΡΕΣΙΕΣ ΠΟΥ ΠΑΡΕΧΟΝΤΑΙ ΑΠΟ ΕΠΑΓΓΕΛΜΑΤΙΕΣ
                  ΥΓΕΙΑΣ ΟΙ ΟΠΟΙΟΙ ΕΙΝΑΙ ΕΞΟΙΚΕΙΩΜΕΝΟΙ ΜΕ ΤΟ ΑΤΟΜΙΚΟ ΙΣΤΟΡΙΚΟ
                  ΣΑΣ. ΝΑ ΣΥΜΒΟΥΛΕΥΕΣΤΕ ΠΑΝΤΑ ΤΟΝ ΓΙΑΤΡΟ ΣΑΣ Ή ΟΠΟΙΟΝΔΗΠΟΤΕ
                  ΑΛΛΟΝ ΕΠΑΓΓΕΛΜΑΤΙΑ ΥΓΕΙΑΣ ΓΙΑ ΟΠΟΙΟΔΗΠΟΤΕ ΘΕΜΑ ΥΓΕΙΑΣ ΠΡΟΤΟΥ
                  ΞΕΚΙΝΗΣΕΤΕ ΚΑΠΟΙΑ ΝΕΑ ΘΕΡΑΠΕΙΑ. Η ΧΡΗΣΗ ΑΥΤΟΥ ΤΟΥ ΔΙΚΤΥΑΚΟΥ
                  ΤΟΠΟΥ ΑΠΟ ΕΣΑΣ ΥΠΟΚΕΙΤΑΙ ΣΕ ΠΡΟΣΘΕΤΕΣ ΤΥΧΟΝ ΔΙΑΤΑΞΕΙΣ
                  ΑΠΟΠΟΙΗΣΗΣ ΕΥΘΥΝΩΝ ΚΑΙ ΠΡΟΕΙΔΟΠΟΙΗΣΕΙΣ ΠΟΥ ΚΥΚΛΟΦΟΡΟΥΝ ΣΤΟ
                  ΔΙΑΔΙΚΤΥΟ.
                </p>
                <p>
                  ΔΕΝ ΦΕΡΟΥΜΕ ΚΑΜΙΑ ΕΥΘΥΝΗ ΓΙΑ ΟΠΟΙΑΔΗΠΟΤΕ ΣΥΝΕΠΕΙΑ ΠΟΥ ΑΦΟΡΑ,
                  ΑΜΕΣΑ Ή ΕΜΜΕΣΑ, ΚΑΠΟΙΑ ΔΡΑΣΗ Ή ΕΛΛΕΙΨΗ ΔΡΑΣΗΣ ΑΠΟ ΤΗΝ ΠΛΕΥΡΑ
                  ΣΑΣ ΜΕ ΒΑΣΗ ΤΙΣ ΠΛΗΡΟΦΟΡΙΕΣ, ΥΠΗΡΕΣΙΕΣ Ή ΆΛΛΟ ΥΛΙΚΟ ΤΟΥ
                  ΔΙΚΤΥΑΚΟΥ ΤΟΠΟΥ.
                </p>
                <p>
                  <strong>Κυριότητα</strong>
                </p>
                <p>
                  Αντιλαμβάνεστε ότι ο Δικτυακός Τόπος σάς είναι διαθέσιμος μόνο
                  για προσωπική, μη εμπορική χρήση. Συμφωνείτε ότι ο Δικτυακός
                  Τόπος αποτελεί ιδιοκτησία του Κατόχου του Δικτυακού Τόπου,
                  συμπεριλαμβανομένων όλων των σχετικών δικαιωμάτων πνευματικής
                  ιδιοκτησίας σε αυτόν, καθώς και ότι δικαιούστε να τα
                  χρησιμοποιήσετε μόνο όπως ορίζεται στους εν λόγω Όρους Χρήσης.
                  Δεν φέρουμε καμία ευθύνη για τυχόν ζημία ή απώλεια που μπορεί
                  να υποστείτε σε σχέση με οποιαδήποτε χρήση του Δικτυακού Τόπου
                  για επαγγελματικούς ή άλλους μη εξουσιοδοτημένους σκοπούς
                  σύμφωνα με αυτούς τους Όρους Χρήσης. Το δικαίωμα να αρνηθούμε
                  ή να διακόψουμε την πρόσβαση στον Δικτυακό Τόπο εναπόκειται
                  στη διακριτική μας ευχέρεια. Ο Δικτυακός Τόπος σάς παρέχεται
                  δωρεάν και, σε αυτή τη βάση, δεν έχουμε καμία υποχρέωση να
                  παρέχουμε οποιεσδήποτε υπηρεσίες συντήρησης ή τεχνικής
                  υποστήριξης σχετικά με αυτόν, ούτε φέρουμε ευθύνη για τυχόν
                  απώλεια ή ζημία που μπορεί να υποστείτε ως αποτέλεσμα τυχόν
                  αδυναμίας συντήρησης ή ενημέρωσης του Δικτυακού Τόπου.
                </p>
                <p>
                  Δεν επιτρέπεται η αντιγραφή, αλλαγή ή επανάχρηση του Δικτυακού
                  Τόπου, καθώς και οποιαδήποτε ενημέρωση, μερική ή ολική, στα
                  στοιχεία του, συμπεριλαμβανομένου του ενσωματωμένου
                  λογισμικού.
                </p>
                <p>
                  Η χρήση του Δικτυακού Τόπου επιτρέπεται μόνο για νόμιμους
                  σκοπούς και σύμφωνα με αυτούς τους Όρους Χρήσης. Σας
                  χορηγείται μια μη αποκλειστική, μη μεταβιβάσιμη, ανέκκλητη,
                  περιορισμένη άδεια προβολής, εκτύπωσης και διανομής
                  περιεχομένου που ανακτάται από τον Δικτυακό Τόπο για
                  προσωπικούς, μη εμπορικούς σκοπούς, εφόσον δεν αφαιρείτε ή
                  επικαλύπτετε την ανακοίνωση δικαιωμάτων πνευματικής
                  ιδιοκτησίας ή άλλες ανακοινώσεις που εμφανίζονται επάνω στο
                  περιεχόμενο. Δεν επιτρέπεται η αντιγραφή, ανατύπωση, προβολή,
                  εκτέλεση, μετάφραση, διανομή, προσαρμογή, μετάδοση,
                  επικοινωνία στο ευρύ κοινό μέσω τηλεπικοινωνιών, η κυκλοφορία
                  ή η πώληση του περιεχομένου που ανακτάται από τον Δικτυακό
                  Τόπο με οποιονδήποτε τρόπο, για τυχόν εμπορική χρήση, ή η
                  παροχή του σε οποιαδήποτε εμπορική πηγή, συμπεριλαμβανομένων
                  άλλων δικτυακών τόπων, χωρίς την προηγούμενη γραπτή άδεια του
                  Κατόχου του Δικτυακού Τόπου.
                </p>
                <p>
                  Επιπλέον, συμφωνείτε να μην: (i) χρησιμοποιήσετε αυτό τον
                  Δικτυακό Τόπο με οποιονδήποτε τρόπο θα μπορούσε να προκαλέσει
                  απενεργοποίηση, υπερφόρτωση, ζημία ή βλάβη στον εν λόγω
                  Δικτυακό Τόπο, ή να παρέμβει σε οποιαδήποτε άλλη χρήση του εν
                  λόγω Δικτυακού Τόπου, συμπεριλαμβανομένης οποιασδήποτε
                  ικανότητας του χρήστη να συμμετέχει σε δραστηριότητες σε
                  πραγματικό χρόνο μέσω αυτού του Δικτυακού Τόπου, (ii)
                  χρησιμοποιήσετε οποιοδήποτε αυτόματο πρόγραμμα τύπου Robot ή
                  Spider ή άλλη αυτόματη συσκευή, διαδικασία ή μέσο για την
                  πρόσβαση σε αυτό τον Δικτυακό Τόπο για οποιονδήποτε σκοπό,
                  συμπεριλαμβανομένης της εξαγωγής και εξόρυξης δεδομένων, της
                  παρακολούθησης ή αντιγραφής οποιουδήποτε υλικού σε αυτό τον
                  Δικτυακό Τόπο, (iii) χρησιμοποιήσετε τυχόν χειροκίνητη
                  διαδικασία για την παρακολούθηση ή αντιγραφή οποιουδήποτε
                  υλικού σε αυτό τον Δικτυακό Τόπο, ή συμμετάσχετε σε
                  οποιονδήποτε άλλο μη εξουσιοδοτημένο σκοπό χωρίς την
                  προηγούμενη γραπτή ρητή άδεια του Κατόχου του Δικτυακού Τόπου,
                  (iv) χρησιμοποιήσετε με κάποιον άλλο τρόπο οποιαδήποτε
                  συσκευή, λογισμικό ή διαδικασία που παρεμβαίνει στην ορθή
                  λειτουργία αυτού του Δικτυακού Τόπου, ή (v) επιχειρήσετε με
                  κάποιον άλλο τρόπο να παρέμβετε στην ορθή στην ορθή λειτουργία
                  αυτού του Δικτυακού Τόπου.
                </p>
                <p>
                  Διατηρούμε όλα τα δικαιώματα που δεν σας εκχωρούνται ρητά.
                </p>
                <p>
                  <strong>Ο Λογαριασμός και Κωδικός Πρόσβασής σας</strong>
                </p>
                <p>
                  Για δικτυακούς τόπου που ζητούν από το χρήστη να δημιουργήσει
                  λογαριασμό και κωδικό πρόσβασης, κατά την πρώτη σας εγγραφή
                  για να χρησιμοποιήσετε αυτό τον Δικτυακό Τόπο, μπορεί να σας
                  ζητηθεί να δημιουργήσετε έναν κωδικό πρόσβασης. Πρέπει να μην
                  αποκαλύπτετε αυτόν τον κωδικό πρόσβασης και να μην τον δίνετε
                  σε κανέναν άλλο ούτε να επιτρέπετε σε κάποιον άλλο να
                  χρησιμοποιήσει το λογαριασμό σας. Δεν πρέπει να χρησιμοποιείτε
                  τον κωδικό πρόσβασης ή τον λογαριασμό κάποιου άλλου. Εάν
                  υποψιαστείτε τυχόν μη εξουσιοδοτημένη χρήση ή πρόσβαση στον
                  κωδικό ή τον λογαριασμό σας, θα πρέπει να μας ενημερώσετε
                  άμεσα. Δεν φέρουμε καμία ευθύνη αν υποστείτε τυχόν βλάβη ή
                  απώλεια επειδή αποκαλύψατε τον κωδικό πρόσβασής σας, ή
                  επιτρέψατε σε κάποιον άλλο να χρησιμοποιήσει τον λογαριασμό
                  σας, ή χρησιμοποιήσατε τον κωδικό πρόσβασης ή τον λογαριασμού
                  κάποιου άλλου, ή αν αντιληφθείτε ή υποψιάζεστε τυχόν μη
                  εξουσιοδοτημένη χρήση του κωδικού ή του λογαριασμού σας αλλά
                  δεν μας ενημερώσετε εγκαίρως.
                </p>
                <p>
                  <strong>Απόρρητο και Συναίνεση Χρήσης Δεδομένων</strong>
                </p>
                <p>
                  Οι πληροφορίες που αποκτούμε μέσω της χρήσης του Δικτυακού
                  Τόπου από εσάς, στο πλαίσιο της διαδικασίας εγγραφής ή με
                  άλλον τρόπο, υπόκεινται στην{" "}
                  <a title="Πολιτική Απορρήτου" href="/gr/legal/cookie-policy/">
                    Πολιτική Απορρήτου{" "}
                  </a>
                  μας. Η Πολιτική Απορρήτου μας αφορά τη συλλογή και χρήση των
                  δεδομένων που μας παρέχετε, συμπεριλαμβανομένων των
                  δικαιωμάτων σας σχετικά με τις εν λόγω πληροφορίες.
                </p>
                <p>
                  <strong>Ηλεκτρονικές Επικοινωνίες</strong>
                </p>
                <p>
                  Οι πληροφορίες που επικοινωνούνται στον Δικτυακό Τόπο
                  αποτελούν ηλεκτρονική επικοινωνία. Όταν επικοινωνείτε μαζί μας
                  μέσω του Δικτυακού Τόπου ή άλλων μορφών ηλεκτρονικών μέσων,
                  όπως ηλεκτρονικό ταχυδρομείο, επικοινωνείτε μαζί μας
                  ηλεκτρονικά. Συμφωνείτε ότι μπορούμε να επικοινωνούμε μαζί σας
                  ηλεκτρονικά, στο πλαίσιο των τοπικών νόμων περί απορρήτου,
                  καθώς και ότι οι εν λόγω επικοινωνίες, καθώς και οι
                  ανακοινώσεις, κοινοποιήσεις, συμφωνίες, και άλλες επικοινωνίες
                  που σας παρέχουμε ηλεκτρονικά, ισοδυναμούν με γραπτές
                  επικοινωνίες και έχουν την ίδια ισχύ που θα είχαν αν ήταν
                  γραπτές και υπογεγραμμένες από το μέρος που αποστέλλει την
                  επικοινωνία.
                </p>
                <p>
                  <strong>Υποβολές από τον Χρήστη</strong>
                </p>
                <p>
                  Δημιουργήσαμε, ή μπορούμε να δημιουργήσουμε στο μέλλον,
                  ορισμένους χώρους, όπως πίνακες ανακοινώσεων και "δωμάτια
                  συνομιλίας" (chat rooms) στον Δικτυακό Τόπο, όπου τα μέλη
                  μπορούν να μεταφορτώσουν και να μοιραστούν σκέψεις, σχόλια,
                  αξιολογήσεις προϊόντων, εικόνες, βίντεο, κλπ., με άλλα μέλη
                  (συλλογικά, τα “Φόρα"). Αυτά τα Φόρα είναι διαθέσιμα μόνο στα
                  εγγεγραμμένα μέλη του Δικτυακού Τόπου. Προκειμένου να
                  εξασφαλιστεί ότι το κείμενο, τα γραφικά, το λογισμικό, η
                  μουσική, ο ήχος, οι φωτογραφίες, τα βίντεο, τα μηνύματα, οι
                  αναρτήσεις, τα δεδομένα, οι πληροφορίες, ή το λοιπό υλικό που
                  αναρτάται από τους χρήστες (συλλογικά, το "Περιεχόμενο
                  Χρήστη") ωφελεί την ευρύτερη κοινότητα, θεσπίσαμε κάποιους
                  βασικούς κανόνες που πρέπει να τηρούνται από όλους.
                </p>
                <p>
                  Επιπλέον, αν παρέχεται κάποιο Φόρουμ μέσα στον Δικτυακό Τόπο,
                  μπορεί να επιτρέπει στους χρήστες να αναρτούν ερωτήσεις για να
                  απαντηθούν από εμπειρογνώμονες που προσλαμβάνονται από εμάς.
                  Όπου κρίνεται σκόπιμο, οι εν λόγω εμπειρογνώμονες πληρώνονται
                  από εμάς για το χρόνο που αναλώνουν προκειμένου να απαντήσουν
                  στις ερωτήσεις των μελών, αλλά εκφράζουν τις δικές τους
                  απόψεις. Εμείς παρέχουμε πρόσβαση στους εμπειρογνώμονες και
                  στην καθοδήγησή τους στο πλαίσιο του Φόρουμ αποκλειστικά και
                  μόνο με τη μορφή ενημερωτικής και εκπαιδευτικής υπηρεσίας στα
                  μέλη του Δικτυακού Τόπου.
                </p>
                <p>
                  Η πολιτική μας για αυτό τον Δικτυακό Τόπο είναι η αξιολόγηση
                  του Περιεχομένου Χρήστη, στο βαθμό που αυτό είναι πρακτικά
                  εφικτό, είτε πριν είτε μετά την ανάρτηση του εν λόγω
                  Περιεχομένου Χρήστη. Εκ των προτέρων έλεγχος σημαίνει ότι το
                  Περιεχόμενο Χρήστη δεν θα αναρτάται στο Φόρουμ και δεν θα
                  είναι δυνατή η πρόσβαση σε αυτό ή η προβολή του από άλλα μέλη
                  του Δικτυακού Τόπου, μέχρις ότου το αξιολογήσουμε και
                  καθορίσουμε ότι το Περιεχόμενο Χρήστη είναι κατάλληλο για
                  ανάρτηση στο Φόρουμ. Αυτή η διαδικασία σημαίνει επίσης ότι το
                  Περιεχόμενο Χρήστη που υποβάλλεται για ανάρτηση δεν θα
                  αναρτάται σε πραγματικό χρόνο, μπορεί μάλιστα να μην αναρτηθεί
                  ποτέ αν εμείς κρίνουμε ότι είναι ακατάλληλο για τον Δικτυακό
                  Τόπο. Εκ των υστέρων έλεγχος σημαίνει ότι το Περιεχόμενο
                  Χρήστη θα αξιολογείται μετά την ανάρτησή του στον Δικτυακό
                  Τόπο. Το Περιεχόμενο Χρήστη που υποβάλλεται σε εκ των υστέρων
                  έλεγχο και το οποίο κρίνεται από τον Κάτοχο του Δικτυακού
                  Τόπου μη συμβατό με τους εν λόγω Όρους Χρήσης ή άλλως
                  ακατάλληλο για τον Δικτυακό Τόπο θα αφαιρείται από τον
                  Δικτυακό Τόπο, χωρίς σχετική ειδοποίηση προς τον Χρήστη.
                </p>
                <p>
                  Το Περιεχόμενο Χρήστη πρέπει να είναι κατάλληλο για το Φόρουμ.
                  Χωρίς αυτό να σημαίνει περιορισμό των λόγων για τους οποίους
                  μπορούμε να απορρίψουμε το Περιεχόμενο Χρήστη, μπορούμε να
                  απορρίψουμε, να επεξεργαστούμε ή να αφαιρέσουμε οποιοδήποτε
                  Περιεχόμενο Χρήστη που δεν συμμορφώνεται με τυχόν{" "}
                  <strong>Κατευθυντήριες Γραμμές της Κοινότητας</strong>, τις
                  οποίες πρέπει να διαβάσετε προτού αναρτήσετε οποιοδήποτε
                  Περιεχόμενο Χρήστη στον Δικτυακό Τόπο.
                </p>
                <p>
                  Εάν υποβάλετε Περιεχόμενο Χρήστη προς ανάρτηση σε ένα Φόρουμ,
                  αυτό σημαίνει ότι (1) επιβεβαιώνετε ότι δικαιούστε να αναρτάτε
                  ή να μεταδίδετε Περιεχόμενο Χρήστη, (2) συμφωνείτε ότι είστε
                  αποκλειστικά υπεύθυνοι για το σύνολο του Περιεχομένου Χρήστη
                  που υποβάλλετε προς ανάρτηση, και (3) εκχωρείτε στον Κάτοχο
                  του Δικτυακού Τόπου και στους συνδεδεμένους φορείς αυτού
                  ανέκκλητα, παγκόσμια, μη αποκλειστικά, μόνιμα, πλήρως
                  εκχωρούμενα, μεταβιβάσιμα και χωρίς χρέωση για εκμετάλλευση
                  δικαίωμα και άδεια χρήσης του δικού σας Περιεχομένου Χρήστη,
                  με οποιονδήποτε τρόπο, στον Δικτυακό Τόπο και σε οποιονδήποτε
                  άλλο Δικτυακό Τόπο τον οποίο κατέχει ή λειτουργεί ο Κάτοχος
                  του Δικτυακού Τόπου ή οι συνδεδεμένοι φορείς αυτού (καθώς και
                  σε οποιουσδήποτε δικτυακούς τόπους μέσων κοινωνικής δικτύωσης
                  που σχετίζονται με τον Κάτοχο του Δικτυακού Τόπου ή με τους
                  συνδεδεμένους φορείς αυτού).
                </p>
                <p>
                  <strong>
                    Είμαστε ελεύθεροι να χρησιμοποιήσουμε κάθε ιδέα, έννοια,
                    τεχνογνωσία ή τεχνική που περιέχεται ή προκύπτει από
                    οποιοδήποτε Περιεχόμενο Χρήστη για κάθε σκοπό,
                    συμπεριλαμβανομένης της ανάπτυξης, κατασκευής και εμπορίας
                    προϊόντων. Κατά συνέπεια, μην μας υποβάλλετε ή αποστέλλετε
                    οποιαδήποτε ιδέα, πρόταση ή υλικό που επιθυμείτε να
                    κρατήσετε εμπιστευτικά ή για τα οποία αναμένετε να λάβετε
                    αποζημίωση. Δεν θα έχετε καμία αξίωση από εμάς όσον αφορά
                    οποιαδήποτε χρήση ή μη χρήση του Περιεχομένου Χρήστη.
                  </strong>{" "}
                  Όταν υποβάλλετε κάποιο Περιεχόμενο Χρήστη, αντιλαμβάνεστε ότι
                  έχουμε το δικαίωμα, αλλά όχι και την υποχρέωση, να
                  χρησιμοποιήσουμε, εμφανίσουμε και δημοσιεύσουμε το όνομα, τη
                  φωτογραφία, την εικόνα ή τη φωνή σας, ή μια παράσταση όπου
                  εμφανίζεστε, καθώς και βιογραφικά στοιχεία ή/και δηλώσεις σας,
                  σε όλο τον κόσμο και επ' άπειρον, στον Δικτυακό Τόπο και σε
                  οποιαδήποτε κανάλια συνδεδεμένου ή διάδοχου Δικτυακού Τόπου ή
                  μέσων κοινωνικής δικτύωσης τα οποία κατέχουν ή λειτουργούν οι
                  συνδεδεμένοι φορείς μας. Εάν κάνουμε χρήση οποιωνδήποτε
                  τέτοιων δικαιωμάτων, αντιλαμβάνεστε και συμφωνείτε ότι δεν θα
                  λάβετε καμία άλλη αμοιβή, πληρωμή, ειδοποίηση ή πίστωση, ούτε
                  θα έχετε λόγο ως προς την έγκριση του τρόπου χρήσης τους από
                  τον Κάτοχο του Δικτυακού Τόπου.
                </p>
                <p>
                  Αποδέχεστε ότι τα Φόρα περιέχουν τις γνώμες και απόψεις άλλων
                  χρηστών. Αποδέχεστε επιπλέον ότι δεν φέρουμε καμία ευθύνη για
                  την ακρίβεια οποιουδήποτε Περιεχομένου Χρήστη στον Δικτυακό
                  Τόπο. Αντιλαμβάνεστε και συμφωνείτε ότι μοναδικός υπεύθυνος
                  για το σύνολο του Περιεχομένου Χρήστη είναι το άτομο που
                  ανάρτησε το εν λόγω Περιεχόμενο. Αντιλαμβάνεστε και συμφωνείτε
                  ότι θα αξιολογείτε, και αναλαμβάνετε κάθε ευθύνη σχετικά με,
                  τη χρήση οποιουδήποτε Περιεχομένου Χρήστη, όπως να στηρίζεστε
                  στην ακρίβεια, πληρότητα ή χρησιμότητα του εν λόγω
                  Περιεχομένου.
                </p>
                <p>
                  Εναπόκειται στη διακριτική μας ευχέρεια να διακόψουμε ανά πάσα
                  στιγμή, προσωρινά ή μόνιμα, τη δυνατότητά σας για μεταφόρτωση
                  ή/και πρόσβαση σε Περιεχόμενο Χρήστη με ή χωρίς ειδοποίηση.
                  Συμφωνείτε ότι δεν θα είμαστε υπεύθυνοι απέναντι σε εσάς ή
                  οποιονδήποτε τρίτο για τυχόν αναστολή ή διακοπή της αποδοχής
                  του Περιεχομένου Χρήστη.{" "}
                </p>
                <p>
                  Υπενθυμίζεται ότι το σύνολο του Περιεχομένου Χρήστη που
                  υποβάλλετε στον Δικτυακό Τόπο μέσω κινητής συσκευής θα
                  αποθηκεύεται στην εν λόγω κινητή συσκευή. Εσείς είστε
                  αποκλειστικά υπεύθυνοι για την προστασία και ασφάλεια της
                  κινητής συσκευής σας, για τις πληροφορίες και τα υλικά που
                  περιέχονται σε αυτήν, καθώς και για οποιονδήποτε κωδικό
                  πρόσβασης που χρησιμοποιείτε στη συσκευή σας και για πρόσβαση
                  στον Δικτυακό Τόπο.
                </p>
                <p>
                  <strong>Αποθήκευση Περιεχομένου Χρήστη</strong>
                </p>
                <p>
                  Μπορούμε να επιβάλουμε ανώτατο όριο στο Περιεχόμενο Χρήστη που
                  αποθηκεύετε στον Δικτυακό Τόπο. Δεν είμαστε υπεύθυνοι για
                  τυχόν απώλεια ή ζημία που μπορείτε να υποστείτε λόγω της
                  διαγραφής ή αποτυχίας αποθήκευσης τυχόν μηνυμάτων,
                  επικοινωνιών ή άλλου Περιεχομένου Χρήστη οι οποίες σχετίζονται
                  με τη διατήρηση του ανώτατου ορίου αποθήκευσης.
                </p>
                <p>
                  <strong>Πρόσθετοι Όροι</strong>
                </p>
                <p>
                  Πρόσθετοι όροι ενδέχεται να διέπουν ορισμένα στοιχεία ή
                  περιεχόμενο στον Δικτυακό Τόπο, όπως προσφορές, κληρώσεις
                  βραβείων, αγώνες, διαγωνισμούς και ιπποδρομιακά λαχεία
                  sweepstake. Η συμμετοχή σας σε οποιαδήποτε δραστηριότητα στον
                  Δικτυακό Τόπο που διέπεται από πρόσθετους όρους, όπως κλήρωση
                  βραβείων, διαγωνισμό, αγώνα ή ιπποδρομιακό λαχείο sweepstake
                  με Επίσημους Κανόνες, σημαίνει πως δέχεστε να υπόκεισθε στους
                  εν λόγω πρόσθετους όρους, επιπλέον αυτών των Όρων Χρήσης.
                </p>
                <p>
                  <strong>
                    Παραίτηση Από Εγγυήσεις σχετικά με τη Χρήση του Δικτυακού
                    Τόπου
                  </strong>
                </p>
                <p>
                  Ο ΔΙΚΤΥΑΚΟΣ ΤΟΠΟΣ ΠΑΡΕΧΕΤΑΙ ΣΕ ΜΙΑ ΒΑΣΗ "ΩΣ ΕΧΕΙ" ΚΑΙ "ΟΠΩΣ
                  ΕΙΝΑΙ ΔΙΑΘΕΣΙΜΟΣ". ΜΕ ΕΞΑΙΡΕΣΗ ΤΙΣ ΕΙΔΙΚΕΣ ΔΙΑΤΑΞΕΙΣ ΠΟΥ
                  ΠΕΡΙΕΧΟΝΤΑΙ ΣΤΟ ΠΑΡΟΝ, ΣΤΟ ΜΕΓΙΣΤΟ ΕΠΙΤΡΕΠΤΟ ΒΑΘΜΟ ΔΥΝΑΜΕΙ ΤΗΣ
                  ΙΣΧΥΟΥΣΑΣ ΝΟΜΟΘΕΣΙΑΣ, Ο ΚΑΤΟΧΟΣ ΤΟΥ ΔΙΚΤΥΑΚΟΥ ΤΟΠΟΥ
                  ΠΑΡΑΙΤΕΙΤΑΙ ΡΗΤΑ ΑΠΟ ΟΛΕΣ ΤΙΣ ΕΓΓΥΗΣΕΙΣ ΟΠΟΙΟΥΔΗΠΟΤΕ ΕΙΔΟΥΣ,
                  ΡΗΤΕΣ, ΝΟΜΙΚΕΣ Ή ΣΥΝΑΓΟΜΕΝΕΣ, ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΩΝ, ΧΩΡΙΣ
                  ΠΕΡΙΟΡΙΣΜΟ, ΤΥΧΟΝ ΕΓΓΥΗΣΕΩΝ ΕΜΠΟΡΕΥΣΙΜΟΤΗΤΑΣ, ΚΑΤΑΛΛΗΛΟΤΗΤΑΣ
                  ΓΙΑ ΣΥΓΚΕΚΡΙΜΕΝΟ ΣΚΟΠΟ ΚΑΙ ΜΗ ΠΑΡΑΒΙΑΣΗΣ.
                </p>
                <p>
                  Ο ΚΑΤΟΧΟΣ ΤΟΥ ΔΙΚΤΥΑΚΟΥ ΤΟΠΟΥ ΔΕΝ ΠΑΡΕΧΕΙ ΚΑΜΙΑ ΕΓΓΥΗΣΗ ΟΤΙ Ο
                  ΔΙΚΤΥΑΚΟΣ ΤΟΠΟΣ ΘΑ ΠΛΗΡΟΙ ΤΙΣ ΑΠΑΙΤΗΣΕΙΣ ΣΑΣ, Ή ΟΤΙ Η ΠΡΟΣΒΑΣΗ
                  ΣΤΟΝ ΔΙΚΤΥΑΚΟ ΤΟΠΟ ΘΑ ΕΙΝΑΙ ΑΔΙΑΚΟΠΗ, ΕΓΚΑΙΡΗ, ΑΣΦΑΛΗΣ Ή
                  ΑΛΑΝΘΑΣΤΗ, Ή ΟΤΙ ΤΥΧΟΝ ΣΦΑΛΜΑΤΑ ΘΑ ΔΙΟΡΘΩΝΟΝΤΑΙ.
                </p>
                <p>
                  ΑΝΤΙΛΑΜΒΑΝΕΣΤΕ ΚΑΙ ΣΥΜΦΩΝΕΙΤΕ ΟΤΙ ΟΠΟΙΟΔΗΠΟΤΕ ΥΛΙΚΟ Ή/ΚΑΙ
                  ΔΕΔΟΜΕΝΑ ΠΟΥ ΜΕΤΑΦΟΡΤΩΝΟΝΤΑΙ Ή ΑΛΛΩΣ ΑΠΟΚΤΟΥΝΤΑΙ ΜΕΣΩ ΤΗΣ
                  ΧΡΗΣΗΣ ΤΟΥ ΔΙΚΤΥΑΚΟΥ ΤΟΠΟΥ ΧΡΗΣΙΜΟΠΟΙΟΥΝΤΑΙ ΜΕ ΔΙΚΗ ΣΑΣ ΕΥΘΥΝΗ
                  ΚΑΙ ΟΤΙ ΕΣΕΙΣ ΕΙΣΤΕ ΑΠΟΚΛΕΙΣΤΙΚΑ ΥΠΕΥΘΥΝΟΙ ΓΙΑ ΤΥΧΟΝ ΖΗΜΙΑ ΣΤΟ
                  ΣΥΣΤΗΜΑ ΤΟΥ ΥΠΟΛΟΓΙΣΤΗ ΣΑΣ Ή ΓΙΑ ΑΠΩΛΕΙΑ ΔΕΔΟΜΕΝΩΝ ΛΟΓΩ ΤΗΣ
                  ΜΕΤΑΦΟΡΤΩΣΗΣ ΤΕΤΟΙΟΥ ΕΙΔΟΥΣ ΥΛΙΚΟΥ Ή/ΚΑΙ ΔΕΔΟΜΕΝΩΝ.
                </p>
                <p>
                  ΚΑΜΙΑ ΣΥΣΤΑΣΗ Ή ΠΛΗΡΟΦΟΡΙΑ, ΠΡΟΦΟΡΙΚΗ Ή ΓΡΑΠΤΗ, ΤΗΝ ΟΠΟΙΑ
                  ΑΠΟΚΤΑΤΕ ΑΠΟ ΤΟΝ ΚΑΤΟΧΟ ΤΟΥ ΔΙΚΤΥΑΚΟΥ ΤΟΠΟΎ Ή ΜΕΣΩ ΤΟΥ
                  ΔΙΚΤΥΑΚΟΥ ΤΟΠΟΥ, ΔΕΝ ΣΥΝΙΣΤΑ ΕΓΓΥΗΣΗ ΕΑΝ ΑΥΤΗ ΔΕΝ ΑΝΑΦΕΡΕΤΑΙ
                  ΡΗΤΑ ΣΤΟ ΠΑΡΟΝ.
                </p>
                <p>
                  <strong>Περιορισμοί Ευθύνης</strong>
                </p>
                <p>
                  ΑΝΤΙΛΑΜΒΑΝΕΣΤΕ ΚΑΙ ΣΥΜΦΩΝΕΙΤΕ ΡΗΤΑ ΟΤΙ Ο ΚΑΤΟΧΟΣ ΤΟΥ ΔΙΚΤΥΑΚΟΥ
                  ΤΟΠΟΥ, ΟΙ ΣΥΝΔΕΔΕΜΕΝΟΙ ΦΟΡΕΙΣ ΤΟΥ ΚΑΙ ΟΠΟΙΟΣΔΗΠΟΤΕ ΑΠΟ ΤΟΥΣ
                  ΑΝΤΙΣΤΟΙΧΟΥΣ ΔΙΕΥΘΥΝΤΕΣ, ΣΤΕΛΕΧΗ, ΥΠΑΛΛΗΛΟΥΣ, ΣΥΝΕΡΓΑΤΕΣ,
                  ΕΝΤΟΛΟΔΟΧΟΥΣ Ή ΆΛΛΟΥΣ ΕΚΠΡΟΣΩΠΟΥΣ, ΣΕ ΚΑΜΙΑ ΠΕΡΙΠΤΩΣΗ ΔΕΝ ΘΑ
                  ΘΕΩΡΟΥΝΤΑΙ ΥΠΕΥΘΥΝΟΙ ΓΙΑ ΑΜΕΣΕΣ, ΕΜΜΕΣΕΣ, ΕΙΔΙΚΕΣ,
                  ΠΕΡΙΣΤΑΣΙΑΚΕΣ, ΕΠΑΚΟΛΟΥΘΕΣ, ΑΝΤΑΠΟΔΟΤΙΚΕΣ Ή ΕΠΙΔΕΙΝΟΥΜΕΝΕΣ
                  ΒΛΑΒΕΣ, ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΗΣ, ΧΩΡΙΣ ΠΕΡΙΟΡΙΣΜΟ, ΟΠΟΙΑΣΔΗΠΟΤΕ
                  ΑΠΩΛΕΙΑΣ ΕΣΟΔΩΝ, ΟΙΚΟΝΟΜΙΩΝ Ή ΚΕΡΔΩΝ, ΑΠΩΛΕΙΑΣ ΚΑΛΗΣ ΠΙΣΤΗΣ,
                  ΚΟΣΤΟΥΣ ΠΡΟΜΗΘΕΙΑΣ ΥΠΟΚΑΤΑΣΤΑΤΩΝ ΥΠΗΡΕΣΙΩΝ, Ή ΓΙΑ ΚΑΘΕ ΑΛΛΗ
                  ΑΜΕΣΗ, ΕΜΜΕΣΗ, ΕΙΔΙΚΗ, ΠΕΡΙΣΤΑΣΙΑΚΗ Ή ΕΠΑΚΟΛΟΥΘΗ ΒΛΑΒΗ,
                  ΑΝΕΞΑΡΤΗΤΩΣ ΑΙΤΙΑΣ, ΚΑΘΩΣ ΚΑΙ ΓΙΑ ΚΑΘΕ ΑΛΛΗ ΘΕΩΡΙΑ ΑΠΟΔΟΣΗΣ
                  ΕΥΘΥΝΩΝ, ΕΙΤΕ ΓΙΑ ΑΘΕΤΗΣΗ ΣΥΜΒΑΣΗΣ, ΑΔΙΚΟΠΡΑΞΙΑ
                  (ΣΥΜΠΕΡΙΛΑΜΒΑΝΟΜΕΝΗΣ ΑΜΕΛΕΙΑΣ ΚΑΙ ΑΝΤΙΚΕΙΜΕΝΙΚΗΣ ΕΥΘΥΝΗΣ), Ή
                  ΠΟΥ ΑΛΛΩΣ ΠΡΟΚΥΠΤΕΙ ΑΠΟ (1) ΤΗ ΧΡΗΣΗ, Ή ΤΗΝ ΑΔΥΝΑΜΙΑ ΧΡΗΣΗΣ,
                  ΤΟΥ ΔΙΚΤΥΑΚΟΥ ΤΟΠΟΥ, (2) ΤΟ ΚΟΣΤΟΣ ΠΡΟΜΗΘΕΙΑΣ ΥΠΟΚΑΤΑΣΤΑΤΩΝ
                  ΥΠΗΡΕΣΙΩΝ, ΣΤΟΙΧΕΙΩΝ Ή ΔΙΚΤΥΑΚΩΝ ΤΟΠΩΝ, (3) ΤΗ ΜΗ
                  ΕΞΟΥΣΙΟΔΟΤΗΜΕΝΗ ΠΡΟΣΒΑΣΗ Ή ΑΛΛΟΙΩΣΗ ΤΩΝ ΜΕΤΑΔΟΣΕΩΝ Ή ΔΕΔΟΜΕΝΩΝ
                  ΣΑΣ, (4) ΤΙΣ ΔΗΛΩΣΕΙΣ Ή ΤΗ ΣΥΜΠΕΡΙΦΟΡΑ ΚΑΠΟΙΟΥ ΤΡΙΤΟΥ ΣΤΟΝ
                  ΔΙΚΤΥΑΚΟ ΤΟΠΟ, Ή (5) ΟΠΟΙΟΔΗΠΟΤΕ ΑΛΛΟ ΥΛΙΚΟ ΠΟΥ ΣΧΕΤΙΖΕΤΑΙ ΜΕ
                  ΤΟΝ ΔΙΚΤΥΑΚΟ ΤΟΠΟ. ΟΙ ΕΝ ΛΟΓΩ ΠΕΡΙΟΡΙΣΜΟΙ ΘΑ ΙΣΧΥΟΥΝ
                  ΑΝΕΞΑΡΤΗΤΑ ΑΝ Ο ΚΑΤΟΧΟΣ ΤΟΥ ΔΙΚΤΥΑΚΟΥ ΤΟΠΟΥ ΕΧΕΙ ΛΑΒΕΙ
                  ΣΥΜΒΟΥΛΕΣ ΣΧΕΤΙΚΑ ΜΕ ΤΙΣ ΠΙΘΑΝΟΤΗΤΕΣ ΝΑ ΠΡΟΚΥΨΟΥΝ ΤΕΤΟΙΟΥ
                  ΕΙΔΟΥΣ ΒΛΑΒΕΣ ΚΑΙ ΑΝΕΞΑΡΤΗΤΑ ΑΠΌ ΤΗΝ ΑΔΥΝΑΜΙΑ ΕΠΙΤΕΥΞΗΣ ΤΟΥ
                  ΟΥΣΙΑΣΤΙΚΟΎ ΣΚΟΠΟΥ ΟΠΟΙΟΥΔΗΠΟΤΕ ΠΕΡΙΟΡΙΣΜΕΝΟΥ ΔΙΟΡΘΩΤΙΚΟΥ
                  ΜΕΤΡΟΥ. ΑΥΤΗ Η ΕΞΑΙΡΕΣΗ ΥΠΑΙΤΙΟΤΗΤΑΣ ΘΑ ΙΣΧΥΕΙ ΣΤΟ ΜΕΓΙΣΤΟ
                  ΒΑΘΜΟ ΠΟΥ ΕΠΙΤΡΕΠΕΤΑΙ ΑΠΟ ΤΗΝ ΙΣΧΥΟΥΣΑ ΝΟΜΟΘΕΣΙΑ.
                </p>
                <p>
                  <strong>Ανακοινώσεις Εμπορικών Σημάτων</strong>
                </p>
                <p>
                  Τα εμπορικά σήματα και οι εμπορικές ονομασίες που προβάλλονται
                  σε αυτό τον Δικτυακό Τόπο αποτελούν ιδιοκτησία του Κατόχου του
                  Δικτυακού Τόπου, των συνδεδεμένων φορέων αυτού ή τρίτων. Δεν
                  μπορείτε να χρησιμοποιήσετε ή να προβάλλετε οποιαδήποτε
                  εμπορικά σήματα ή εμπορικές ονομασίες που ανήκουν στον Κάτοχο
                  του Δικτυακού Τόπου χωρίς τη δική μας προηγούμενη γραπτή
                  συναίνεση. Δεν μπορείτε να χρησιμοποιήσετε ή να προβάλλετε
                  οποιαδήποτε άλλα εμπορικά σήματα ή εμπορικές ονομασίες που
                  προβάλλονται σε αυτόν τον Δικτυακό Τόπο χωρίς την άδεια των
                  κατόχων τους.
                </p>
                <p>
                  <strong>
                    Τροποποίηση ή Αναστολή Λειτουργίας του Δικτυακού Τόπου
                  </strong>
                </p>
                <p>
                  Θα καταβάλλουμε εύλογες προσπάθειες να καταστήσουμε τον
                  Δικτυακό Τόπο διαθέσιμο, αλλά μπορεί κατά καιρούς να χρειαστεί
                  να διακόψουμε, περιορίσουμε, τροποποιήσουμε ή διακόψουμε,
                  προσωρινά ή μόνιμα, τη λειτουργία του Δικτυακού Τόπου ή μερών
                  αυτού χωρίς προειδοποίηση. Δεν θα είμαστε υπεύθυνοι για
                  οποιαδήποτε βλάβη ή απώλεια που μπορεί να υποστείτε ως
                  αποτέλεσμα τέτοιου είδος ενεργειών.
                </p>
                <p>
                  <strong>Διακοπή</strong>
                </p>
                <p>
                  Μπορούμε, ανά πάσα στιγμή, να διακόψουμε τη χρήση ή την
                  πρόσβασή σας στον Δικτυακό Τόπο και μπορούμε να διαγράψουμε
                  μέρος ή το σύνολο του Περιεχομένου Χρήστη σας χωρίς
                  προηγούμενη ειδοποίηση, αν έχουμε μια εύλογη αιτία για να το
                  πράξουμε, συμπεριλαμβανομένης οποιασδήποτε παραβίασης από εσάς
                  αυτών των Όρων Χρήσης και των Κατευθυντήριων Γραμμών της
                  Κοινότητας. Δεν θα είμαστε υπεύθυνοι για τυχόν απώλεια ή ζημία
                  που μπορεί να υποστείτε ως αποτέλεσμα της διακοπής της χρήσης
                  του Δικτυακού Τόπου από εσάς υπό αυτές τις συνθήκες. Σε
                  περίπτωση οποιασδήποτε διακοπής, πρέπει να σταματήσετε να
                  χρησιμοποιείτε τον Δικτυακό Τόπο, συμφωνείτε δε ότι οι
                  διατάξεις της Συμφωνίας που αφορούν τα Δικαιώματα Πνευματικής
                  Ιδιοκτησίας, τις Ανακοινώσεις Εμπορικών Σημάτων, την
                  Αποζημίωση, την Παραίτηση από Εγγυήσεις, τους Περιορισμούς
                  Ευθυνών, καθώς και το Ισχύον Δίκαιο, διατηρούνται και μετά από
                  κάθε τέτοιου είδους διακοπή.
                </p>
                <p>
                  <strong>Σύνδεσμοι με Δικτυακούς Τόπους Τρίτων</strong>
                </p>
                <p>
                  Αυτός ο Δικτυακός Τόπος μπορεί να περιλαμβάνει συνδέσμους ή
                  παραπομπές σε δικτυακούς τόπους τρίτων. Αυτοί οι σύνδεσμοι
                  παρέχονται μόνο για τη δική σας ευχέρεια. Καμία επικρότηση
                  τυχόν προϊόντων, υπηρεσιών ή πληροφοριών τρίτων δεν εκφράζεται
                  ρητά ούτε υπονοείται από οποιαδήποτε πληροφορία, υλικό ή
                  περιεχόμενο τρίτου τα οποία περιέχονται ή συμπεριλαμβάνονται,
                  ή για τα οποία υπάρχει παραπομπή ή σύνδεσμος από ή προς τον
                  Δικτυακό Τόπο. Όλες οι πληροφορίες, τα δεδομένα, οι γνώμες, οι
                  συστάσεις, τα προϊόντα ή οι υπηρεσίες που παρέχονται από τους
                  εν λόγω τρίτους μέσω συνδέσμων προς άλλους δικτυακούς τόπους ή
                  που καθίστανται διαθέσιμα με άλλο τρόπο μέσω των δικών τους
                  δικτυακών τόπων, ανήκουν αποκλειστικά στους εν λόγω τρίτους
                  και όχι στον Κάτοχο του Δικτυακού Τόπου. Η χρήση των εν λόγω
                  δικτυακών τόπων τρίτων από εσάς υπόκειται στους όρους χρήσης
                  και στις πολιτικές απορρήτου των εν λόγω δικτυακών τόπων.
                </p>
                <p>
                  <strong>Αποζημίωση</strong>
                </p>
                <p>
                  Συμφωνείτε να αποζημιώσετε και να απαλλάξετε από κάθε ευθύνη
                  τον Κάτοχο του Δικτυακού Τόπου, τους συνδεδεμένους φορείς
                  αυτού και τους αντίστοιχους διευθυντές, στελέχη, υπαλλήλους,
                  συνεργάτες ή άλλους εκπροσώπους από και ενάντια σε όλες τις
                  αξιώσεις, ευθύνες, ζημίες και δαπάνες, συμπεριλαμβανομένων,
                  χωρίς περιορισμό, όλων των νομικών αμοιβών και δαπανών που
                  απορρέουν από ή σχετίζονται με (α) την παραβίαση από εσάς
                  αυτών των Όρων Χρήσης, (β) τη χρήση από εσάς αυτού του
                  Δικτυακού Τόπου, συμπεριλαμβανομένης, χωρίς περιορισμό, της
                  μετάδοσης ή της ανάρτησης πληροφοριών ή υλικού από εσάς σε
                  αυτό τον Δικτυακό Τόπο, και (γ) οποιαδήποτε αξίωση ή ισχυρισμό
                  σύμφωνα με τα οποία κάποιο Περιεχόμενο Χρήστη σας παραβιάζει
                  τα δικαιώματα πνευματικής ιδιοκτησίας ή άλλα δικαιώματα
                  ιδιοκτησίας, ή δικαιώματα απορρήτου, οποιουδήποτε τρίτου.
                </p>
                <p>
                  <strong>Τροποποιήσεις αυτών των Όρων</strong>
                </p>
                <p>
                  Μπορούμε κατά καιρούς να κάνουμε αλλαγές στους Όρους Χρήσης
                  κατά τη διακριτική μας ευχέρεια, ενημερώνοντας αυτούς τους
                  Όρους Χρήσης σε αυτόν τον Δικτυακό Τόπο, και καθορίζοντας την
                  ημερομηνία θέσης σε ισχύ της νέας έκδοσης των Όρων Χρήσης. Η
                  ημερομηνία "Τελευταίας Τροποποίησης" (Last Modified) στην
                  κορυφή αυτών των Όρων Χρήσης θα υποδηλώνει πότε έγιναν οι
                  τελευταίες αλλαγές. Η συνεχιζόμενη χρήση από εσάς του
                  Δικτυακού Τόπου μετά την ανάρτηση μιας νέας έκδοσης των Όρων
                  Χρήσης σημαίνει ότι αποδέχεστε τις εν λόγω αλλαγές. Κατά
                  συνέπεια, κάθε φορά που επισκέπτεστε αυτό τον Δικτυακό Τόπο,
                  θα πρέπει να ελέγχετε αν έχει αναρτηθεί μια νέα έκδοση της
                  Συμφωνίας.
                </p>
                <p>
                  <strong>Πληροφορίες Επικοινωνίας</strong>
                </p>
                <p>
                  Εάν έχετε απορίες ή ανησυχίες σχετικά με αυτούς τους Όρους
                  Χρήσης ή τον Δικτυακό Τόπο, μπορείτε να επικοινωνήσετε μαζί
                  μας όπως υποδεικνύεται στην Πολιτική Απορρήτου ή στην ενότητα
                  "Επικοινωνία" (Contact Us) αυτού του Δικτυακού Τόπου.
                </p>
                <p>
                  <strong>Δυνατότητα Διαχωρισμού της Συμφωνίας</strong>
                </p>
                <p>
                  Σε περίπτωση που οποιοδήποτε τμήμα ή διάταξη αυτών των Όρων
                  Χρήσης θεωρούνται παράνομα, κενά, άκυρα ή μη εφαρμόσιμα, το εν
                  λόγω τμήμα ή διάταξη θα θεωρούνται διαχωρίσιμα από αυτούς τους
                  Όρους Χρήσης και δεν θα επηρεάζουν την εγκυρότητα και τη
                  δυνατότητα εφαρμογής των υπόλοιπων διατάξεων.
                </p>
                <p>
                  <strong>Διατήρηση</strong>
                </p>
                <p>
                  Όλες οι Ενότητες θα διατηρούνται μετά τη διακοπή του
                  δικαιώματος χρήσης του Δικτυακού Τόπου.
                </p>
                <p>
                  <strong>Ισχύον Δίκαιο και Δικαιοδοσία</strong>
                </p>
                <p>
                  Οι πληροφορίες στον Δικτυακό Τόπο προορίζονται μόνο για χρήση
                  από τους κατοίκους της Ελλάδας. Άλλες χώρες μπορεί να έχουν
                  νόμους, κανονιστικές απαιτήσεις, ή ιατρικές πρακτικές που
                  διαφέρουν από αυτές στην Ελλάδα. Η Συμφωνία και η επίλυση
                  οποιασδήποτε διαφοράς σχετικά με τη Συμφωνία, τον Δικτυακό
                  Τόπο, ή αντικείμενα που αγοράζετε μέσω του Δικτυακού Τόπου,
                  καθώς και οι μη συμβατικές υποχρεώσεις που απορρέουν από ή
                  σχετίζονται με αυτούς τους Όρους Χρήσης, θα διέπονται από και
                  θα ερμηνεύονται σύμφωνα με τους νόμους της Ελλάδας, χωρίς αυτό
                  να θέτει σε ισχύ οποιεσδήποτε αρχές σχετικά με τη σύγκρουση
                  δικαιοδοσίας. Οποιαδήποτε νομική ενέργεια ή υπόθεση ανάμεσα σε
                  εσάς και τον Κάτοχο του Δικτυακού Τόπου σχετικά με τη Συμφωνία
                  θα υποβάλλεται αποκλειστικά ενώπιον ενός αρμόδιου δικαστηρίου
                  με έδρα την Αθήνα, συμφωνείτε δε ότι υπόκεισθε στην ατομική
                  και αποκλειστική δικαιοδοσία των εν λόγω δικαστηρίων.
                </p>
                <p> </p>
              </div>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
       
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $("#bgImage img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$("#bgImage img").remove();\n});\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
